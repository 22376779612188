<template>
  <div>
    {{ famille }}
  </div>
</template>
  
  <script>
  import {mapGetters } from 'vuex'
  export default {
      props: {object: {type: Object, required: true}},
      data(){
          return{
  
          }
      },
      methods: {
          
      },
      computed: {
          ...mapGetters({
            familles: 'sanitaire/familleMedicaments'
          }),
          famille(){
            let f = this.familles.find(item => item.uid === this.object.famille)
            if(f) return f.libelle
            return '-'
          }
      }
  }
  </script>
  
  <style>
  
  </style>